
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IProductListRequest, IProduct } from "@/types/product";
import { IPagination, ISelectItem } from "@/types";
import { IWarehouseListRequest } from "@/types/warehouse";
import { ICustomerListRequest } from "@/types/customer";
import { IMakerListRequest } from "@/types/maker";
import { ICustomerProductListRequest } from "@/types/customer_product";
import { ISupplierListRequest } from "@/types/supplier";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Action("product/adminGetSearchList")
  public getProductSearchList!: (
    request: IProductListRequest
  ) => Promise<boolean>;

  @Getter("product/list")
  public productList!: IProduct[];

  @Getter("product/searchList")
  public productSearchList!: ISelectItem[];

  @Getter("product/pagination")
  public pagination!: IPagination<IProduct[]>;

  @Mutation("product/clear")
  public productClear!: () => void;

  // customer
  @Action("customer/adminGetList")
  public getCustomerList!: (request: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Mutation("customer/clear")
  public customerClear!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  // warehouse
  @Action("warehouse/adminGetList")
  public getWarehouseList!: (
    request: IWarehouseListRequest
  ) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Mutation("warehouse/clear")
  public warehouseClear!: () => void;

  // maker
  @Action("maker/adminGetList")
  public getMakerList!: (request: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Mutation("maker/clear")
  public makerClear!: () => void;

  // supplier
  @Action("supplier/adminGetList")
  public getSupplierList!: (request: ISupplierListRequest) => Promise<boolean>;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Mutation("supplier/clear")
  public supplierClear!: () => void;

  //watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getProductList(this.params);
  }

  // パラメータ定義
  public params: IProductListRequest = {
    page: 1,
    warehouse: 0,
    maker: 0,
    customer: 0,
    product: 0,
    customer_product: 0,
    supplier: 0,
    in_stock: false
  };

  //
  // variables
  //
  public searchInput = "";
  public search_product: ISelectItem | string | null = null;
  public in_stock_check = false;
  public headers = [
    { text: "商品名", value: "name", sortable: false },
    { text: "品番", value: "code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "顧客名", value: "customer_name", sortable: false },
    { text: "メーカ", value: "maker_name", sortable: false },
    { text: "総ロット数", value: "total_lot", sortable: false, align: "end" },
    {
      text: "総在庫",
      value: "stockage_quantity",
      sortable: false,
      align: "end"
    },
    { text: "受発注", value: "ordering", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.productClear();
    this.customerClear();
    this.customerProductClear();
    this.warehouseClear();
    this.makerClear();
    this.supplierClear();
    this.queryToParams();

    await Promise.all([
      this.getProductList(this.params),
      this.getProductSearchList({ per_page: 0 }),
      this.getCustomerList({ per_page: 0 }),
      this.getCustomerProductList({ per_page: 0 }),
      this.getWarehouseList({ per_page: 0 }),
      this.getMakerList({ per_page: 0 }),
      this.getSupplierList({ per_page: 0 })
    ]);

    if (this.params.search) {
      this.search_product = this.params.search;
    }

    if (this.params.product) {
      this.search_product =
        this.productSearchList.find(item => {
          return item.value == this.params.product;
        }) || null;
    }
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      search: query.search ? decodeURI(query.search as string) : "",
      page: query.page ? Number(query.page) : 1,
      warehouse: query.warehouse ? Number(query.warehouse) : 0,
      maker: query.maker ? Number(query.maker) : 0,
      customer: query.customer ? Number(query.customer) : 0,
      product: query.product ? Number(query.product) : 0,
      customer_product: query.customer_product
        ? Number(query.customer_product)
        : 0,
      supplier: query.supplier ? Number(query.supplier) : 0,
      in_stock: query.in_stock ? Boolean(query.in_stock) : false
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    if (!this.search_product) {
      this.params.search = "";
      this.params.product = 0;
    } else if (typeof this.search_product == "string") {
      this.params.search = this.search_product;
    } else if (typeof this.search_product == "object") {
      this.params.product = Number(this.search_product.value);
    }
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          page: this.params.page ? String(this.params.page) : "1",
          warehouse: this.params.warehouse
            ? String(this.params.warehouse)
            : "0",
          maker: this.params.maker ? String(this.params.maker) : "0",
          customer: this.params.customer ? String(this.params.customer) : "0",
          product: this.params.product ? String(this.params.product) : "0",
          customer_product: this.params.customer_product
            ? String(this.params.customer_product)
            : "0",
          supplier: this.params.supplier ? String(this.params.supplier) : "0",
          in_stock: this.params.in_stock ? String(this.params.in_stock) : ""
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(product: IProduct) {
    this.$router.push(`/admin/inventory/${product.id}/state`);
  }

  // v-comboboxのリストを再フィルターするための処理
  public forceFilter() {
    if (typeof this.search_product == "string") {
      this.search_product += " ";
      setTimeout(() => {
        this.searchInput = String(this.searchInput).replace(/\s+/g, "");
      }, 4);
    }
  }
}
